import requestService from "../requestService";
import store from '@/store';

export default {
    async createDiscount(form) {
        const response = await requestService.post(`/admin/discount`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async updateDiscount(id, form) {
        const response = await requestService.post(`/admin/discount/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async getDiscounts() {
        const response = await requestService.get(`/discount`)
        return response?.data
    },
    async getDiscountForAdmin(status, type) {
        const response = await requestService.get(`/admin/discount?type=${type}${status && status !== 'All' ? `&status=${status}` : ''}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async getDiscount(id) {
        const response = await requestService.get(`/admin/discount/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async deleteDiscount(id) {
        const response = await requestService.delete(`/admin/discount/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
}