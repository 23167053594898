import requestService from "../requestService";
import store from "@/store";

export default {
    async getOrders(type, page) {
        const response = await requestService.get(`/order/list/${type}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async getOrderById(id) {
        const response = await requestService.get(`/order/${id}`)
        return response?.data
    },
    async getOrderByIdAdmin(id) {
        const response = await requestService.get(`/admin/order/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async getOrderByUuid(uuid) {
        if (store.getters.loggedUser) {
            const response = await requestService.get(`/order/${uuid}`, {
                headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
            })
            return response?.data
        } else {
            const response = await requestService.get(`/order/${uuid}`)
            return response?.data
        }
    },
    async getOrderByQr(id) {
        const response = await requestService.get(`/order_scan_qr/${id}`)
        return response?.data
    },
    async sendOrderToEmailAdmin(id) {
        const response = await requestService.post(`/admin/order/send_email/${id}`, {}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
    async bookingTiceket(form) {
        if (store.getters.loggedUser) {
            const response = await requestService.post(`/order`, form, {
                headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
            });
            return response?.data
        } else {
            const response = await requestService.post(`/order`, form);
            return response?.data
        }
    },
    async createOrder(form, order_uid) {
        if (store.getters.loggedUser) {
            const response = await requestService.post(`/order/${order_uid}`, form, {
                headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
            });
            return response?.data
        } else {
            const response = await requestService.post(`/order/${order_uid}`, form);
            return response?.data
        }
    },
    async changeOrderStatus(id, form) {
        const response = await requestService.post(`/admin/order/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async cancelOrderForUser(id, form) {
        const response = await requestService.post(`/order/cancel/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async cancelOrderForAdmin(id) {
        const response = await requestService.post(`/admin/order/cancel/${id}`,{}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async sendOrderToEmail(uuid) {
        const response = await requestService.get(`/order/ticket/${uuid}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async getOrderMessages() {
        const response = await requestService.get(`/admin/order_message`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async getOrderMessagesByStatus(status) {
        const response = await requestService.get(`/admin/order_message/list/${status}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async getOrderMessage(id) {
        const response = await requestService.get(`/admin/order_message/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async updateStatusOrderMessage(id, form) {
        const response = await requestService.post(`/admin/order_message/${id}`, form, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async deleteOrderMessage(id) {
        const response = await requestService.get(`/admin/order_message/${id}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async changeSeatsQuantity(id, form) {
        if (store.getters.loggedUser) {
            const response = await requestService.post(`/order/seats/${id}`, form, {
                headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
            });
            return response?.data
        } else {
            const response = await requestService.post(`/order/seats/${id}`, form);
            return response?.data
        }
    },
    async payTicket(id) {
        const response = await requestService.post(`/order/pay/${id}`, {}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async preOrderTicket(id) {
        const response = await requestService.post(`/order/booking/${id}`, {}, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        });
        return response?.data
    },
    async getOrdesByUser(user_id, page) {
        const response = await requestService.get(`/admin/order/user/${user_id}?page=${page}`, {
            headers: { Authorization: `Bearer ${store.getters.loggedUser.token}` }
        })
        return response?.data
    },
}