<template>
  <div class="myTicketCard">
    <booking-ticket-time-box
      style="margin-bottom: 20px"
      v-if="ticket.status == 'Booking'"
      :booking_at="subtractDaysFromDate(ticket.trip_info.departure_date, 1)"
    />
    <v-row no-gutters align="center">
      <v-col
        cols="4"
        xl="6"
        lg="3"
        md="3"
        sm="4"
        class="px-0 py-0"
        style="text-align: left"
      >
        <p class="myTicketCardTitle">
          {{ ticket?.departure_stations?.city?.name }}
        </p>
        <p class="myTicketCardText">
          <span style="font-weight: 600" v-if="ticket.trip_info">
            {{ ticket?.departure_stations?.arrival }},</span
          >
          {{
            ticket?.trip_info?.route_info?.route_cities.filter(
              (city) => city.city_uuid == ticket.departure_city
            )?.[0].bus_station.address
          }}
        </p>
      </v-col>
      <v-col cols="4" xl="6" lg="3" md="3" sm="4" class="py-0">
        <v-row no-gutters align="center">
          <v-col
            cols="1"
            xl="2"
            lg="2"
            md="2"
            sm="1"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <v-row no-gutters align="center">
              <div
                style="
                  width: 16px;
                  height: 16px;
                  background: #fff;
                  border: 2px solid #b5b5b5;
                  border-radius: 50%;
                "
                v-if="!$vuetify.breakpoint.smAndDown"
              ></div>
              <div
                style="height: 0.5px; background: #b5b5b5"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'width: 100%'
                    : ' width: calc(100% - 16px);'
                "
              ></div>
            </v-row>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12">
            <v-row no-gutters align="center" justify="center">
              <span
                class="myTicketCardText"
                style="
                  font-size: 14px;
                  font-weight: 500;
                  color: #989898;
                  margin-bottom: 4px;
                "
                >{{
                  setDifferentTime(
                    addMinutesToDate(
                      ticket?.trip_info?.departure_date,
                      ticket?.departure_stations?.time_from_start
                    ),
                    addMinutesToDate(
                      ticket?.trip_info?.departure_date,
                      ticket?.arrival_stations?.time_from_start
                    )
                  )
                }}</span
              >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="#989898"
                    size="14px"
                    style="margin-left: 4px"
                    >mdi-help-circle-outline</v-icon
                  >
                </template>
                <span>Час відправлення та прибуття місцевий</span>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col
            cols="1"
            xl="2"
            lg="2"
            md="2"
            sm="1"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <v-row no-gutters align="center">
              <div
                style="height: 0.5px; background: #b5b5b5"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'width: 100%'
                    : ' width: calc(100% - 16px);'
                "
              ></div>
              <div
                style="
                  width: 16px;
                  height: 16px;
                  background: #fff;
                  border: 2px solid #b5b5b5;
                  border-radius: 50%;
                  display: grid;
                  place-items: center;
                "
                v-if="!$vuetify.breakpoint.smAndDown"
              >
                <div
                  style="
                    width: 10px;
                    height: 10px;
                    background: #b5b5b5;
                    border-radius: 50%;
                  "
                ></div>
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="4"
        xl="6"
        lg="3"
        md="3"
        sm="4"
        class="px-0 py-0"
        style="text-align: left"
      >
        <p class="myTicketCardTitle">
          {{ ticket?.arrival_stations?.city?.name }}
        </p>
        <p class="myTicketCardText">
          <span style="font-weight: 600">
            {{ ticket?.arrival_stations?.arrival }},</span
          >
          {{
            ticket?.trip_info?.route_info?.route_cities.filter(
              (city) => city.city_uuid == ticket.arrival_city
            )?.[0].bus_station.address
          }}
        </p>
      </v-col>
      <v-col
        cols="12"
        xl="3"
        lg="3"
        md="3"
        sm="12"
        style="text-align: right"
        class="px-0 py-0"
      >
        <v-row
          no-gutters
          align="center"
          :justify="$vuetify.breakpoint.smAndDown ? 'end' : 'center'"
          :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 12px' : ''"
        >
          <div
            v-if="ticket.status == 'Payed'"
            class="iconBackground"
            @click="showQrCodeModal = true"
          >
            <div class="qrCodeIcon" />
          </div>
          <div
            v-if="ticket.status == 'Payed'"
            class="iconBackground"
            style="margin-left: 12px"
            @click="downloadQrToPDF"
          >
            <div class="downloadIcon" />
          </div>
          <div
            v-html="payBtn"
            :key="key"
            v-if="
              ticket.status == 'Booking' &&
              new Date() <
                subtractDaysFromDate(ticket.trip_info.departure_date, 1)
            "
          />
          <div
            v-html="payBtn"
            :key="key"
            v-if="ticket.status == 'Pending' && payBtn"
          />
          <v-btn
            v-if="ticket.status == 'Pending' && ticket.ticket.length == 0"
            text
            color="#fff"
            class="cancelTripBtn"
            style="
              color: #144fa9 !important;
              width: max-content !important;
              font-weight: 500;
            "
            @click="continueBooking"
            >Продовжити бронювання</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
    <div
      style="background: #f6f8fc; width: 100%; height: 2px; margin: 14px 0px"
    />
    <v-row no-gutters>
      <v-col cols="8" xl="4" lg="4" md="4" sm="6">
        <span class="myTicketCardText" style="font-size: 12px">Автобус:</span>
        <p class="myTicketCardTitle" style="font-size: 14px; margin-top: 4px">
          {{ ticket?.trip_info?.route_info?.bus_info?.model }}
        </p>
      </v-col>
      <v-col cols="4" xl="3" lg="3" md="3" sm="6">
        <span class="myTicketCardText" style="font-size: 12px">Платформа:</span>
        <p
          class="myTicketCardTitle"
          style="font-size: 14px; margin-top: 4px; color: #1b1b1b"
        >
          №
          {{
            ticket?.trip_info?.route_info?.route_cities.find(
              (city) => city.city_uuid == ticket.departure_city
            )?.platform
          }}
        </p>
      </v-col>
      <v-col cols="8" xl="3" lg="3" md="3" sm="6">
        <span class="myTicketCardText" style="font-size: 12px"
          >Кількість місць:</span
        >
        <p
          class="myTicketCardTitle"
          style="font-size: 14px; margin-top: 4px; color: #1b1b1b"
        >
          {{ ticket.ticket.length }}
        </p>
      </v-col>
      <v-col cols="4" xl="2" lg="2" md="2" sm="6">
        <span class="myTicketCardText" style="font-size: 12px">Місце:</span>
        <p
          class="myTicketCardTitle"
          style="font-size: 14px; margin-top: 4px; color: #1b1b1b"
        >
          Вільне
        </p>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" style="margin-top: 20px">
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <button
          class="detailBtn"
          :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : ''"
          @click="showTicketDetail = !showTicketDetail"
        >
          Детальніше
          <v-icon style="margin-left: 10px">{{
            showTicketDetail ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </button>
      </v-col>
      <v-col cols="12" xl="6" lg="6" md="6" sm="12">
        <v-row
          no-gutters
          align="center"
          :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'end'"
          v-if="
            (ticket.status == 'Pending' &&
              ticket.ticket.length >= 0 &&
              orderType !== 'Inactive') ||
            (ticket.status == 'Booking' &&
              ticket.ticket.length >= 0 &&
              orderType !== 'Inactive') ||
            (ticket.status == 'Payed' &&
              ticket.ticket.length >= 0 &&
              orderType !== 'Inactive' &&
              isShowCancelBtn)
          "
        >
          <v-btn
            text
            color="#CA0000"
            class="cancelTripBtn"
            @click="(showConfirmModal = true), (isPrebooking = true)"
            >Скасувати поїздку</v-btn
          >
        </v-row>
      </v-col>
    </v-row>

    <v-expand-transition>
      <v-col class="px-0 py-0" v-show="showTicketDetail">
        <div
          style="
            width: 100%;
            height: 1px;
            margin-top: 12px;
            background-color: #0858951a;
          "
        ></div>
        <my-ticket-card-detail
          style="margin-top: 12px"
          :services="ticket?.trip_info?.route_info?.bus_info?.options"
          :ticket="ticket"
          @downloadTicketByIndex="downloadPDFByIndex"
          @close="showTicketDetail = false"
        />
      </v-col>
    </v-expand-transition>
    <confirm-modal
      v-if="showConfirmModal"
      :visible="showConfirmModal"
      :refund="true"
      :modalText="refundText"
      modalTitle="Скасувати бронювання"
      modalSubtitle="Ви впевнені, що бажаєте скасувати бронювання квитка?"
      @close="showConfirmModal = false"
      @confirm="refundOrder"
    />
    <success-modal
      v-if="reservationCanceled"
      :visible="reservationCanceled"
      modalTitle="Ваше бронювання скасовано!"
      modalText="Найближчим часом наш менеджер зв’яжиться з Вами для уточнення деталей."
      @close="reservationCanceled = false"
    />
    <qr-code-modal
      v-if="showQrCodeModal"
      :visible="showQrCodeModal"
      :text="qrText"
      :ticket="ticket"
      @close="showQrCodeModal = false"
    />
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="`Квиток ${ticket?.departure_info?.name} - ${
        ticket?.arrival_info?.name
      } ${new Date(
        addMinutesToDate(
          ticket?.trip_info?.departure_date,
          ticket?.departure_stations?.time_from_start
        )
      ).toLocaleDateString('uk-UA', { month: 'short', day: 'numeric' })}`"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      :pdf-margin="100"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <pdf-ticket
          id="pdfTicket"
          :ticket="ticket"
          :isPdf="isPdf"
          :text="qrText"
          :indexTicket="indexTicket"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>
    
<script>
import successModal from "@/components/UI/modals/successModal.vue";
import ConfirmModal from "@/components/UI/modals/confirmModal.vue";
import QrCodeModal from "@/components/UI/modals/qrCodeModal.vue";
import diffrentTimeMixin from "@/mixins/diffrentTimeMixin";
import bookingTicketService from "@/requests/main/bookingTicketService";
import VueHtml2pdf from "vue-html2pdf";
import PdfTicket from "@/components/forUser/pdfTicket.vue";
import MyTicketCardDetail from "./myTicketCardDetail.vue";
import BookingTicketTimeBox from "../../UI/bookingTicketTimeBox.vue";
export default {
  mixins: [diffrentTimeMixin],
  components: {
    successModal,
    ConfirmModal,
    QrCodeModal,
    VueHtml2pdf,
    PdfTicket,
    MyTicketCardDetail,
    BookingTicketTimeBox,
  },
  data: () => ({
    showTicketDetail: false,
    reservationCanceled: false,
    showConfirmModal: false,
    showQrCodeModal: false,
    isShowCancelBtn: true,
    isPrebooking: false,
    quantity_seats: 0,
    qrText: "",
    refundText: localStorage.getItem("return_conditions").replaceAll('"', ""),
    payBtn: "",
    downloadPDF: false,
    sendEmail: false,
    isPdf: true,
    indexTicket: 0,
    key: 0,
  }),
  props: {
    ticket: {
      require: true,
    },
    orderType: {
      require: true,
    },
  },
  mounted() {
    this.setQuantity();
    this.setQrText();
    if (
      (this.ticket.ticket.length > 0 && this.ticket.status == "Booking") ||
      (this.ticket.ticket.length > 0 && this.ticket.status == "Pending")
    ) {
      this.payTicket();
    }
    this.setShowCancelBtn();
  },
  methods: {
    setQuantity() {
      this.quantity_seats =
        this.ticket.ticket.length > 0 ? this.ticket.ticket.length : 1;
    },
    setQrText() {
      this.qrText = `${location.protocol}//${location.host}/order_qr/${this.ticket.uuid}`;
    },
    setShowCancelBtn() {
      const departureDate = new Date(
        this.addMinutesToDate(
          this.ticket?.trip_info?.departure_date,
          this.ticket?.departure_stations?.time_from_start
        )
      ); //Departure date
      const currentDate = new Date(); // Current date and time
      const timeDifference = departureDate.getTime() - currentDate.getTime(); // Difference data and time
      const oneHourInMilliseconds = 60 * 60 * 1000; // One hours in milisecond
      const oneDayInMilliseconds = 24 * oneHourInMilliseconds; // One day in milisecond
      const diffInDays = timeDifference / oneDayInMilliseconds; // Differenxe date in day
      this.isShowCancelBtn = diffInDays > 1;
    },
    async refundOrder() {
      let form = new FormData();
      form.append("status", this.isPrebooking ? "Canceled" : "Refunded");
      await bookingTicketService
        .cancelOrderForUser(this.ticket.uuid, form)
        .then(() => {
          this.showConfirmModal = false;
          this.reservationCanceled = true;
          if (this.isPrebooking) {
            localStorage.removeItem("trip_id");
            localStorage.removeItem("order_uuid");
          }
          this.isPrebooking = false;
          this.$emit("getOrders");
        });
    },
    continueBooking() {
      sessionStorage.setItem("order_uuid", this.ticket.uuid);
      sessionStorage.setItem("trip_id", this.ticket.trip_info.id);
      this.$router.push(
        `/tickets/${
          this.ticket.trip_info.id
        }/booking?quantityPeople=${1}&departureCity=${
          this.ticket.departure_city
        }&arrivalCity=${this.ticket.arrival_city}`
      );
      this.$emit("close");
    },
    async payTicket() {
      await bookingTicketService.payTicket(this.ticket.uuid).then((res) => {
        if (res.status == "Success") {
          this.payBtn = res.data.replace(
            'src="//static.liqpay.ua/buttons/p1ru.radius.png"',
            'src="https://i.ibb.co/cgNmzFQ/pay-btn.png" name="ticket_btn_text"'
          );
          setTimeout(() => {
            this.key++;
          }, 500);
        }
      });
    },
    async downloadQrToPDF() {
      for (const [index] of this.ticket.ticket.entries()) {
        this.indexTicket = index;
        this.isPdf = true;
        this.isDownloadTicket = true;
        await this.$refs.html2Pdf.generatePdf();
        this.downloadPDF = true;
        await new Promise((resolve) => setTimeout(resolve, 5000));
        this.downloadPDF = false;
      }
    },
    async downloadPDFByIndex(index) {
      this.indexTicket = index;
      this.isPdf = true;
      this.isDownloadTicket = true;
      await this.$refs.html2Pdf.generatePdf();
      this.downloadPDF = true;
      await new Promise((resolve) => setTimeout(resolve, 5000));
      this.downloadPDF = false;
    },
  },
};
</script>
    
    <style scoped>
@media (max-width: 1039px) {
  .myTicketCard {
    padding: 16px;
    border-radius: 10px;
    background: #fcfcfc;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .myTicketCardTitle {
    font-size: 16px;
    font-weight: 700;
    color: #144fa9;
    margin: 0px;
  }
  .myTicketCardText {
    font-size: 12px !important;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0px;
    margin-top: 4px;
  }
  .myTicketCardSubtitle {
    font-size: 14px;
    font-weight: 400;
    color: #989898;
  }
  .passangerBox {
    display: flex;
    padding: 12px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #f5f5f5;
    font-size: 16px;
    font-weight: 600;
  }
  .detailBtn {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 4px 8px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
@media (min-width: 1040px) {
  .myTicketCard {
    padding: 16px;
    border-radius: 10px;
    background: #fcfcfc;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
  }
  .myTicketCardTitle {
    font-size: 20px;
    font-weight: 700;
    color: #144fa9;
    margin: 0px;
  }
  .myTicketCardText {
    font-size: 14px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0px;
    margin-top: 4px;
  }
  .myTicketCardSubtitle {
    font-size: 14px;
    font-weight: 400;
    color: #989898;
  }
  .passangerBox {
    display: flex;
    padding: 12px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #f5f5f5;
    font-size: 16px;
    font-weight: 600;
  }
  .detailBtn {
    border-radius: 10px;
    background: rgba(20, 79, 169, 0.1);
    padding: 4px 8px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1b1b1b;
    font-family: "MacPaw Fixel";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.qrCodeIcon {
  width: 24px;
  height: 24px;
  background-color: #144fa9;
  -webkit-mask: url("../../../assets/img/iconsSvg/qrCodeIcon.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/qrCodeIcon.svg") no-repeat center;
}
.downloadIcon {
  width: 24px;
  height: 24px;
  background-color: #144fa9;
  -webkit-mask: url("../../../assets/img/iconsSvg/downloadIcon.svg") no-repeat
    center;
  mask: url("../../../assets/img/iconsSvg/downloadIcon.svg") no-repeat center;
}
.iconBackground {
  border-radius: 10px;
  background: rgba(8, 88, 149, 0.1);
  padding: 8px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.iconBackground:hover {
  background-color: #144fa9;
}
.iconBackground:hover .qrCodeIcon {
  background-color: #fafafa;
}
.iconBackground:hover .downloadIcon {
  background-color: #fafafa;
}
.cancelTripBtn {
  width: 180px !important;
  height: 35px !important;
  display: grid;
  place-items: center;
  border-radius: 10px !important;
  color: #df3a3a !important;
  font-family: "MacPaw Fixel";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
  margin-top: 12px;
}
</style>
<style>
input[name="ticket_btn_text"] {
  width: 200px !important;
  height: 38px !important;
  object-fit: cover !important;
  border-radius: 10px;
}
</style>