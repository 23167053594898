<template>
  <v-dialog v-model="visibility">
    <div style="background: #fcfcfc; padding: 16px; overflow-x: hidden">
      <step-to-discount
        :buyedTicket="buyedTicket"
        :quantitySteps="quantitySteps"
        :discount="discount"
        style="background: transparent"
      />
      <v-row no-gutters justify="center">
        <admin-form-button
          width="100%"
          @click="$emit('close')"
          style="
            width: 100% !important;
            margin-top: 20px;
            min-width: 100px !important;
          "
          >Закрити</admin-form-button
        >
      </v-row>
    </div>
  </v-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import stepToDiscount from "./stepToDiscount.vue";
import AdminFormButton from "../../UI/buttons/adminFormButton.vue";
export default {
  components: { stepToDiscount, AdminFormButton },
  mixins: [modalMixin],
  props: {
    buyedTicket: {
      require: true,
    },
    quantitySteps: {
      require: true,
    },
    discount: {
      require: true,
    },
  },
};
</script>

<style>
</style>