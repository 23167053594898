export default {
    state: {
        currency: { value: 1, text: 'UAH', currency: 'грн' },
    },
    mutations: {
        setCurrency(state, currency) {
            state.currency = currency.toUpdate
        },
    },
    actions: {
        async updateCurrency({ commit, getters }, toUpdate) {
            try {
                const updateData = { ...getters.currency, toUpdate }
                commit('setCurrency', updateData)
            }

            catch (e) {
                commit('setError', e)
                throw e
            }
        },
    },
    getters: {
        currency: s => s.currency
    }
}
